import { SWRConfig } from 'common/hooks/useApi'
import { IUserProfile } from 'services/account/account.types'
import { http } from 'services/http'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'

export const ApiPathsSettings = {
  profile: `auth/profile`,
  payments: 'payments',
  companySubscriptions: (recurrence: 'month' | 'year' = 'month') =>
    `payments/company-subscriptions?&recurrence=${recurrence}`,
  companyUnlockCandidate: (id: string) => `companies/subscriptions/unlock-candidate/${id}`,
  unsubscribe: `/auth/user/unsubscribe`,
  currentUser: `users/current`,
}

export const useUnsubscribe = (config: SWRMutationConfiguration<any, any, any, string> = {}) => {
  const { trigger, isMutating } = useSWRMutation(ApiPathsSettings.unsubscribe, putUnsubscribe, { ...config })

  return { trigger, isMutating }
}

export const putUnsubscribe = async (url: string, { arg }: { arg: { email: string } }) => {
  const { data } = await http.put(`${url}/${arg.email}`)
  return data
}

export const getUserProfile = async (url: string): Promise<IUserProfile> => {
  const { data } = await http.get(url)

  return data
}

export const useGetUserProfile = (config: SWRConfig<IUserProfile> = {}) => {
  const { data, error, isLoading, mutate } = useSWRImmutable(ApiPathsSettings.profile, getUserProfile, { ...config })

  return {
    data,
    error,
    isLoading,
    mutate,
  }
}
